<template>
  <div class="guide-cnt14">
    <h6 class="h6">取消订单</h6>
    <div class="cnt">
      <p class="p">如何取消订单？</p>
      <p class="p">具体操作步骤如下：</p>
      <p class="p">进入“我的订单”页面，找到要取消的订单，点击“取消订单”按钮；</p>
      <p class="p">选择订单取消原因后，提交申请；</p>
      <p class="p">“取消订单”申请成功后，您可在“我的订单”页面查看取消进度。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt14',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt14 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:10px;
    }
  }
}
</style>
